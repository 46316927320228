import React, { useState, useEffect } from "react";
import { Row, Select } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import Image from "../../components/ImageQuerys/RandomImages";
import { careersHero, jobOppenings } from "../../data/careers-data";
import {
  getAllDepartments,
  getSalesDepartments,
  getCorporateDepartments,
  fetchSome,
} from "../../api/greenhouse-api";
import CareersDisplay from "../../components/Careers/CareersDisplay";
import Subverticals from "../../components/SubVerticals/Subverticals";
import JobsSearch from "../../components/Careers/JobsSearch";
import {
  careersMain,
  careersQuick,
  largeRecruiters,
  ourValues,
} from "../../data/main-pages/careers-sections";
import LargeFeatures from "../../components/LargeFeatures/LargeFeatures";
import OurValues from "../../components/OurValues/OurValues";
import LargeRecruit from "../../components/LargeFeatures/LargeRecruit";
import Section from "../../components-v2/Base/Section";
import Offices from "../../components/Offices/Offices";
import CareersDisplayV2 from "../../components/Careers/CareersDisplay-v2";
import Heading from "../../components-v2/Base/HeadingBuilder";
import NoVisualsCustom from "../../components/NoVisuals/NoVisualsCustom";
import chevron from "../../images/svg/chevron_left.svg";
import ogImage from "../../images/global_assets/og-image.png";

const heroBg = "careers-hero.png";
const mobileBg = "mobile-careers.png";
const img = "gptw-badges.png";
const bg = "random-bg-blur.png";

const ProductJobsPage = () => {
  const [departments, setDepartments] = useState({
    sales: [],
    product: [],
    corp: [],
  });
  useEffect(() => {
    getAllDepartments()
      .then((areas) => {
        const prefilter = areas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          product: prefilter,
        }));
        return getSalesDepartments();
      })
      .then((salesareas) => {
        const prefilter = salesareas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          sales: prefilter,
        }));
        return getCorporateDepartments();
      })
      .then((corpareas) => {
        const prefilter = corpareas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          corp: prefilter,
        }));
      });
  }, []);

  return (
    <Layout>
      <SEO
        title="Careers & Job Culture at SpotOn Point of Sale | SpotOn "
        description="Find a work family not a normal job. We help our employees with growth and team building, for one common goal. Read reviews & join the #SpotOnFamily now."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/careers/"
      />
      <a
        className={`
          bg-primary text-white hover:text-white
          fixed top-[30%] -right-16
          rounded-[16px]
          text-[18px] py-4 px-8
          transform -rotate-90
          cursor-pointer group
          z-[11]
        `}
        href="#openings"
      >
        Apply Here
        <img
          src={chevron}
          alt="chevron"
          className="group-hover:translate-x-2 transition transition-all ml-2"
        />
      </a>
      <BHero
        sectionData={careersHero}
        heroBg={heroBg}
        mobileBg={mobileBg}
        clearBg
        id="careers-bg"
        className="object-scale-down"
      />

      <div className="my-20 md:my-32">
        <NoVisualsCustom sectionData={careersQuick} simple isPageUrl />
      </div>

      <Section className="">
        <CareersDisplayV2 careersDepartments={departments?.product || []} />
      </Section>

      <Section className="mt-16">
        <Offices />
      </Section>

      <Section className="mt-10 md:mt-20">
        <Heading
          className="text-center"
          level={2}
          injectionType={2}
          withBlueSymbol
          symbol="!"
        >
          We’re a great place to work! Join us
        </Heading>

        <Image
          imageName="great-place-to-work-badges.png"
          className="max-w-[640px] mx-auto mt-10 mb-40"
        />
      </Section>
    </Layout>
  );
};

export default ProductJobsPage;
